.viewcontact {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home51.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.viewcontact .title {
  font-size: 50rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #04317C;
  line-height: 59rem;
  -webkit-background-clip: text;
  position: absolute;
  top: 11.83vh;
  width: 100%;
  text-align: center;
}

.viewcontact .imageLine {
  height: 16rem;
  width: 367rem;
  top: 17.71vh;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewcontact .viewcontent {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home52.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 572rem;
  width: 1104rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.viewcontact .textcontent {
  width: 572rem;
  font-size: 34rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 40rem;
  -webkit-background-clip: text;
  position: absolute;
  top: 130rem;
  left: 217rem;
}

.viewcontact .line2 {
  margin-top: 34rem;
}

.viewcontact .line3 {
  margin-top: 38rem;
}

.viewcontact .bottom {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 96rem;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.viewcontact .bottomtext {
  font-size: 18rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 21rem;
  -webkit-background-clip: text;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.viewcontact .bottom .text2 {
  margin-left: 105rem;
  margin-right: 80rem;
  cursor: pointer;
}

.viewcontact .bottom .guohui {
  height: 21rem;
  width: 21rem;
  object-fit: contain;
}

.viewcontact .bottom .bottomtext2 {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.viewcontact .bottom .text2:hover {
  color: #0037FA;
}

.viewcontact .bottom .bottomtext2:hover {
  color: #0037FA;
}
