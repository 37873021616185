.viewhome {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  /* width: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.viewhome .textHeader {
  font-size: 67rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 79rem;
  letter-spacing: 4rem;
  -webkit-text-stroke: 1rem #000000;
  text-stroke: 1rem #000000;
  -webkit-background-clip: text;
  margin-left: 17.71vw;
}

.viewhome .textBlue {
  color: #0037FA;
  -webkit-text-stroke: 1rem #005FFA;
  text-stroke: 1rem #005FFA;
  -webkit-background-clip: text;

}

.viewhome .textHeaderEN {
  font-size: 51rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #181818;
  line-height: 62rem;
  letter-spacing: 2rem;
  -webkit-background-clip: text;
  margin-left: 17.71vw;
  margin-top: 20rem;
}

.viewhome .viewbottom {
  width: 554rem;
  height: 79rem;
  background: linear-gradient(253deg, rgba(253, 243, 174, 0.86) 6%, rgba(115, 252, 229, 0.75) 29%, #46BFFD 51%, #4277FF 67%);
  /* box-shadow: 0px 4px 42px 0px rgba(94, 151, 246, 0.54); */
  border-radius: 50px 10px 50px 10px;
  opacity: 1;
  margin-left: 17.71vw;
  margin-top: 67rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewhome .viewbottom .textbottom {
  font-size: 44rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 79rem;
  letter-spacing: 3rem;
  text-shadow: 0px 0px 25rem rgba(12, 55, 166, 0.68);
  -webkit-background-clip: text;
}