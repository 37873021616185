.mobilemainpage .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80rem;
  background-color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.mobilemainpage .header::after  {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  opacity: 0.3; /* 透明度 */
}

.mobilemainpage .header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* 模糊效果 */
  opacity: 0.3; /* 透明度 */
}

.hide-scrollbar {
  overflow: hidden;
}

.mobilemainpage .header .logo {
  height: 44rem;
  width: 236rem;
}

.mobilemainpage .logoout {
  position: absolute;
  top: 0;
  height: 44rem;
  width: 236rem;
  z-index: 3;
  top: 18rem;
}

.mobilemainpage {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.mobilemainpage .divImageBack {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  z-index: 0;
}

.viewMobileAboutUs .divImageBack .backimgonediv {
  display: flex;
  position: relative;
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewMobileAboutUs .divImageBack .backimgonediv .backimgone {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.mobilemainpage .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  z-index: 1;
  height: 9600rem;
}

.mobilemainpage .content .viewmid {
  width: 533rem;
  height: 76rem;
  background: linear-gradient(253deg, rgba(253, 243, 174, 0.86) 6%, rgba(115, 252, 229, 0.75) 29%, #46BFFD 51%, #4277FF 67%);
  /* box-shadow: 0rem 4rem 42rem 0rem rgba(94, 151, 246, 0.54); */
  border-radius: 40rem 5rem 50rem 5rem;
  opacity: 1;
  margin-top: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobilemainpage .content .viewmid .textbottom {
  font-size: 42rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 76rem;
  letter-spacing: 3rem;
  text-shadow: 0rem 0rem 25rem rgba(12, 55, 166, 0.68);
  -webkit-background-clip: text;
}

.mobilemainpage .content .text1 {
  font-size: 63rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 74rem;
  letter-spacing: 4rem;
  -webkit-background-clip: text;
  width: 632rem;
  height: 88rem;
}

.mobilemainpage .content .margintop200 {
  margin-top: 200rem;
}

.mobilemainpage .content .textHeaderBlue {
  color: #0037FA;
  -webkit-text-stroke: 0rem #005FFA;
  text-stroke: 0rem #005FFA;
}

.mobilemainpage .content .text2 {
  height: 110rem;
  font-size: 42rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #181818;
  line-height: 55rem;
  -webkit-background-clip: text;
  margin-top: 28rem;
  width: 650rem;
  margin-left: 20rem;
}

.mobilemainpage .content .text3 {
  height: 78rem;
  font-size: 56rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #04317C;
  line-height: 66rem;
  -webkit-background-clip: text;
  margin-top: 817rem;
  margin-bottom: 108rem;
}

.mobilemainpage .content .text4 {
  height: 73rem;
  font-size: 36rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 73rem;
  letter-spacing: 2rem;
  -webkit-background-clip: text;
  width: 640rem;
  text-align: left;
  margin-left: 16rem;
}

.mobilemainpage .content .text41 {
  letter-spacing: 3rem;
  margin-left: 50rem;
}

.mobilemainpage .content .text42 {
  letter-spacing: 3.3rem;
}

.mobilemainpage .content .text43 {
  width: 654rem;
  letter-spacing: 1.8rem;
  margin-left: 24rem;
}

.mobilemainpage .content .text44 {
  letter-spacing: 5.4rem;
}

.mobilemainpage .content .text45 {}

.mobilemainpage .content .text31 {
  margin-top: 758rem;
  margin-bottom: 108rem;
}

.mobilemainpage .content .text51 {
  letter-spacing: 1rem;
  margin-left: 56rem;
}

.mobilemainpage .content .text52 {
  letter-spacing: 6rem;
}

.mobilemainpage .content .text53 {
  letter-spacing: 6rem;
}

.mobilemainpage .content .text54 {
  letter-spacing: 2.6rem;
}

.mobilemainpage .content .text55 {
  letter-spacing: 2rem;
}

.mobilemainpage .content .text32 {
  margin-top: 612rem;
  margin-bottom: 103rem;
}

.mobilemainpage .content .view6 {
  height: 278rem;
  width: 624rem;
  flex-direction: column;
  position: relative;
  align-items: start;
  justify-content: start;
}

.mobilemainpage .content .view6 .text6 {
  height: 65rem;
  font-size: 54rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #FFEB9F;
  line-height: 65rem;
  letter-spacing: 4rem;
  text-shadow: 0rem 1rem 0rem rgba(0, 0, 0, 0.49);
  -webkit-background-clip: text;
}

.mobilemainpage .content .text33 {
  margin-top: 110rem;
  margin-bottom: 103rem;
}

.mobilemainpage .content .viewproduct {
  height: 1000rem;
  width: 100%;
  /* background-color: red; */
  display: flex;
  position: relative;
}

.mobilemainpage .content .viewproduct .text7 {
  width: 207rem;
  height: 49rem;
  font-size: 40rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 47rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
}

.mobilemainpage .content .viewproduct .text71 {
  position: absolute;
  /* top: 100rem; */
  left: 108rem;
  top: 270rem;
}

.mobilemainpage .content .viewproduct .text72 {
  position: absolute;
  /* top: 100rem; */
  right: 52rem;
  top: 270rem;
}

.mobilemainpage .content .viewproduct .text73 {
  position: absolute;
  /* top: 100rem; */
  left: 108rem;
  top: 870rem;
}

.mobilemainpage .content .viewproduct .text74 {
  position: absolute;
  /* top: 100rem; */
  right: 52rem;
  top: 870rem;
}

.mobilemainpage .content .text80 {
  margin-top: 250rem;
  font-size: 38rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 73rem;
  -webkit-background-clip: text;
  text-align: start;
  width: 600rem;
}

.mobilemainpage .content .text8 {
  font-size: 33rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #414141;
  line-height: 61rem;
  letter-spacing: 2rem;
  -webkit-background-clip: text;
  text-align: left;
  width: 620rem;
}

.mobilemainpage .content .text81 {
  margin-top: 8rem;
  width: 650rem;
  margin-left: 46rem;

}

.mobilemainpage .content .text82 {
  letter-spacing: 0;
  width: 660rem;
  margin-left: 56rem;
}

.mobilemainpage .content .text83 {
  letter-spacing: 6rem;
  width: 650rem;
  margin-left: 48rem;
}

.mobilemainpage .content .text84 {
  letter-spacing: 1rem;
  width: 650rem;
  margin-left: 48rem;
}

.mobilemainpage .content .text34 {
  margin-top: 229rem;
}

.mobilemainpage .content .text9 {
  font-size: 34rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 69rem;
  letter-spacing: 3rem;
  -webkit-background-clip: text;
}

.mobilemainpage .content .text91 {
  margin-top: 25rem;
  letter-spacing: 2rem;
  margin-left: 20rem;
}

.mobilemainpage .content .text92 {
  letter-spacing: 2rem;
}

.mobilemainpage .content .text93 {
  letter-spacing: 4rem;
}

.mobilemainpage .content .text94 {
  letter-spacing: 2rem;
}

.mobilemainpage .content .text95 {
  letter-spacing: 2rem;
  width: 614rem;
  text-align: left;
}

.mobilemainpage .content .text96 {
  margin-top: 56rem;
  letter-spacing: 2rem;
  margin-left: 20rem;
}

.mobilemainpage .content .text97 {
  letter-spacing: 2rem;
}

.mobilemainpage .content .text98 {
  letter-spacing: 2rem;
  width: 614rem;
  text-align: left;
}

.mobilemainpage .content .text35 {
  margin-top: 317rem;
  margin-bottom: 116rem;
}

.mobilemainpage .content .text10 {
  font-size: 36rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 42rem;
  -webkit-background-clip: text;
}

.mobilemainpage .content .text101 {
  width: 360rem;
}

.mobilemainpage .content .text102 {
  width: 416rem;
  margin-top: 40rem;
  margin-bottom: 46rem;
  margin-left: 54rem;
}

.mobilemainpage .content .text103 {
  width: 416rem;
  margin-left: 54rem;
}

.mobilemainpage .content .text201 {
  width: 360rem;
  position: absolute;
  bottom: 834rem;
}

.mobilemainpage .content .text202 {
  width: 416rem;
  position: absolute;
  bottom: 752rem;
  margin-left: 54rem;
}

.mobilemainpage .content .text203 {
  width: 416rem;
  position: absolute;
  bottom: 665rem;
  margin-left: 54rem;

}


.mobilemainpage .content .text11 {
  margin-top: 515rem;
  font-size: 36rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #04317C;
  line-height: 47rem;
  letter-spacing: 15rem;
  -webkit-background-clip: text;
  text-align: center;
  margin-left: 10rem;
}

.mobilemainpage .content .text12 {
  font-size: 36rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #04317C;
  line-height: 47rem;
  letter-spacing: 2rem;
  -webkit-background-clip: text;
  text-align: center;
}

.mobilemainpage .viewbottom .bottomtext2 {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.mobilemainpage .viewbottom {
  /* height: 679rem; */
  height: 300rem;
  width: 100%;
  /* background-color: yellow; */
  /* margin-top: 251rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.mobilemainpage .viewbottom .textbottom {
  font-size: 28rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 33rem;
  -webkit-background-clip: text;
}

.mobilemainpage .viewbottom .guohui {
  height: 26rem;
  width: 26rem;
  object-fit: contain;
  margin-right: 6rem;
}

.mobilemainpage .viewbottom .textbottom1 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}