
.mainpage .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80rem;
  background-color: white;
  z-index: 1;
}

.mainpage .header .logo {
  position: absolute;
  left: 17vw;
  top: 22rem;
  height: 36rem;
  width: 177rem;
  cursor: pointer;
}