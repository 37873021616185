.viewcompany {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home21.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
}

.viewcompany2 {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home222top.png');

}

.viewcompany3 {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home233top.png');

}

.viewcompany .title {
  font-size: 50rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #04317C;
  line-height: 59rem;
  -webkit-background-clip: text;
  position: absolute;
  top: 15.53vh;
  width: 100%;
  text-align: center;
}

.viewcompany .imageLine {
  height: 16rem;
  width: 367rem;
  top: 21.21vh;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewcompany .tabs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 267rem;
}

@keyframes backexchange {
  from {
    background-color: #FFEC9F;
    opacity: 0.5;
  }

  to {
    /* background-color: #EAEAEA; */
    background: linear-gradient(180deg, #FFFFFF 28%, #39F3C6 100%);
    opacity: 1;
  }
}

.viewcompany .tabs .tab {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 65rem;
  width: 221rem;
  background-color: #FFEC9F;
  margin-left: 42rem;
  margin-right: 42rem;
  border-radius: 5rem;
  animation: backexchange 0.3s ease-in-out;
  cursor: pointer;
}



.viewcompany .tabs .tabblue {
  background: linear-gradient(180deg, #FFFFFF 28%, #39F3C6 100%);
  opacity: 1;
  /* animation: backexchange 0.3s ease-in-out; */

  /* border: 2rem solid; */
  /* border-image: linear-gradient(143deg, rgba(92, 255, 235, 1), rgba(255, 255, 255, 1), rgba(80, 255, 255, 1), rgba(0, 24, 243, 1)) 2 2; */
}

.viewcompany .tabs .tab .icon {
  height: 36rem;
  width: 36rem;
}

.viewcompany .tabs .tab .tabtextselect {
  font-size: 24rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 28rem;
  -webkit-background-clip: text;
  margin-left: 16rem;
  transition: color 0.3s ease-in-out;
}

.viewcompany .tabs .tab .tabtext {
  font-size: 24rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #1B969F;
  line-height: 28rem;
  -webkit-background-clip: text;
  margin-left: 16rem;
  transition: color 0.3s ease-in-out;

}

.viewcompany .dots {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 30rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.viewcompany .dots .dotselect {
  width: 42rem;
  height: 15rem;
  background: #FFCC00;
  border-radius: 8rem;
  opacity: 1;
  border: 1rem solid #015FFB;
  /* margin-left: 5rem; */
  margin: auto 5rem;
  transition: background-color 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;

}

.viewcompany .dots .dot {
  width: 15rem;
  height: 15rem;
  background: #EAEAEA;
  border-radius: 8rem;
  opacity: 1;
  border: 1rem solid #015FFB;
  margin: auto 5rem;
  transition: background-color 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;

}

.viewcompany .center {
  height: 694.5rem;
  width: 1276rem;
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home21center.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 26rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.viewcompany .center2 {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home22center.png');
  align-items: center;
  height: 668rem;
  width: 1276rem;
  margin-top: 26rem;
}

.viewcompany .center3 {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home23center.png');
  height: 594rem;
  width: 1200rem;
  margin-top: 46rem;

}

.viewcompany .center .text11 {
  width: 695rem;
  height: 61rem;
  font-size: 30rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 61rem;
  letter-spacing: 1.5rem;
  -webkit-background-clip: text;
  margin-left: 104rem;
  margin-bottom: 4rem;
  text-align: justify;

}

.viewcompany .center .text12 {
  width: 705rem;
  height: 61rem;
  font-size: 30rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 61rem;
  -webkit-background-clip: text;
  margin-left: 70rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  /* text-align: justify; */
  letter-spacing: .8rem;

}

.viewcompany .center .text13 {
  width: 695rem;
  height: 61rem;
  font-size: 30rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 61rem;
  -webkit-background-clip: text;
  margin-left: 70rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: justify;
    letter-spacing: .7rem;

}

.viewcompany .center .text14 {
  width: 652rem;
  height: 61rem;
  font-size: 30rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 61rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  margin-left: 70rem;
  margin-bottom: 40rem;
  margin-top: 4rem;
  letter-spacing: .7rem;
}

.viewcompany .center .text20 {
  height: 61rem;
  font-size: 30rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 61rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: start;
  width: 876rem;
  margin-bottom: 10rem;
}

.viewcompany .center .text21 {
  letter-spacing: 2.2rem;
  margin-left: 34rem;
}

.viewcompany .center .text22 {
  letter-spacing: 1rem;
}

.viewcompany .center .text23 {
  letter-spacing: 1rem;
  margin-bottom: 120rem;
}

.viewcompany .center .blue {
  color: #0037FA;
}

.viewcompany .youxia {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 310rem;
  width: 359rem;
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home2youxia.png');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}