.slider {
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  /* scrollbar-width: thin; 设置滚动条宽度 */
  /* scrollbar-color: transparent transparent; 设置滚动条颜色 */
  overflow-x: hidden; /* 隐藏横向滚动条 */
}

.slider .screen1 {
  height: 100vh;
  scroll-snap-align: start;
  background-color: white;
}

.slider .screen2 {
  height: 100vh;
  scroll-snap-align: start;
  background-color: white;
}

.slider .screen3 {
  height: 100vh;
  scroll-snap-align: start;
  background-color: white;
}

.slider .screen4 {
  height: 100vh;
  scroll-snap-align: start;
  /* background-color: blue; */
}

.slider::-webkit-scrollbar {
  /* width: 5px; 设置滚动条宽度 */
}

.slider::-webkit-scrollbar-thumb {
  /* background-color: transparent; 设置滚动条颜色 */
}