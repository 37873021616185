.viewteam {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home44.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.viewteam .title {
  font-size: 50rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #04317C;
  line-height: 59rem;
  -webkit-background-clip: text;
  position: absolute;
  top: 16.83vh;
  width: 100%;
  text-align: center;
}

.viewteam .imageLine {
  height: 16rem;
  width: 367rem;
  top: 22.71vh;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewteam .textcontent {
  width: 1072rem;
  font-size: 34rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 69rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: justify;
  display: inline-block;
  margin-top: 13vh;
  margin-left: 36rem;
}

.viewteam .line1 {
  margin-left: 8rem;
  letter-spacing: 0rem;
}

.viewteam .line2 {
  letter-spacing: 2.9rem;

}

.viewteam .line3 {
  letter-spacing: 1.7rem;
}

.viewteam .line4 {
  margin-top: 30rem;
  letter-spacing: 3rem;
  width: 1040rem;
}