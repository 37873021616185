.viewservice {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home32.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  position: relative;
}

.viewservice .content {
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/home33.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  position: relative;
}


.viewservice .title {
  font-size: 50rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #04317C;
  line-height: 59rem;
  -webkit-background-clip: text;
  position: absolute;
  top: 10.65vh;
  width: 100%;
  text-align: center;
}

.viewservice .imageLine {
  height: 16rem;
  width: 367rem;
  top: 16.4vh;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.viewservice .textTtitle {
  font-size: 40rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 47rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
}

.viewservice .text1 {
  position: absolute;
  left: 20.15vw;
  top: 45.28vh;
}