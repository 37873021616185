.banner {
  width: 100%;
  overflow: hidden;
  background-color: red;
  height: 100vh;
}

.banner .bannerscreens {
  display: flex;
  transition: transform 0.3s ease;
  background-color: aqua;
  height: 100vh;
}

.banner .bannerscreen1 {
  flex: 0 0 100%;
  width: 100%;
  background-color: purple;
  height: 100vh;
}

.banner .bannerscreen2 {
  flex: 0 0 100%;
  width: 100%;
  background-color: peru;
  height: 100vh;
}

.banner .bannerscreen3 {
  flex: 0 0 100%;
  width: 100%;
  background-color: greenyellow;
  height: 100vh;
}